import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="aggregate"
export default class extends Controller {
  static targets = ["form"];

  connect() {
    document.getElementById("show-fewer")?.classList?.add("hidden");
  }

  submit() {
    this.formTarget.submit();
  }

  expand() {
    document.getElementById("show-more")?.classList?.add("hidden");
    document.getElementById("show-fewer")?.classList?.remove("hidden");
    document.getElementById("subcaplist")?.classList?.remove("collapsed");
  }

  collapse() {
    document.getElementById("show-more")?.classList?.remove("hidden");
    document.getElementById("show-fewer")?.classList?.add("hidden");
    document.getElementById("subcaplist")?.classList?.add("collapsed");
  }
}
