import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.toggleClass = "hidden";
  }

  show(e) {
    const controller = this;

    controller.shown = controller.shown || [];
    const prompt_id = "p" + ("0000" + e.detail.time).slice(-5);
    const targets = controller.containerTargets.filter(
      (t) => controller.shown.indexOf(t.id) === -1 && prompt_id >= t.id
    );
    if (targets.length <= 0) return;

    const target = targets[0];
    if (!target.classList.contains(controller.toggleClass)) return;

    controller.currentPromptIndex = controller.shown.length;
    controller.shown.push(target.id);

    // Unhide the prompt
    target.classList.remove(controller.toggleClass);
  }
}
