<script lang="ts">
	import Icon from '$shared/icons/Icon.svelte';
	import { createEventDispatcher } from 'svelte';

	import TreeShape from '$comp/ui/table/TreeShape.svelte';

	export let isExpanded;
	export let canExpand;
	export let isAllSubRowsExpanded;
	export let depth: any;

	const dispatch = createEventDispatcher();

	function handleExpand() {
		dispatch('expand');
	}

	function handleCollapse() {
		dispatch('collapse');
	}
</script>

<div class="spacer" style:--depth={3} />
{#if $canExpand}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<div style:--depth={depth} class="exp" class:end={depth >= 1}>
		<button
			class="border border-black {$isExpanded ? 'bg-black' : 'bg-white'}  w-4 h-4 rounded relative"
			on:click={() => {
				if (!$isExpanded) {
					handleExpand();
					setTimeout(() => {
						$isExpanded = true;
					}, 0);
				} else {
					handleCollapse();
					$isExpanded = false;
				}
			}}
		>
			{#if $isExpanded}
				{#if $isAllSubRowsExpanded}
					<Icon icon="Minus" iconClass="w-full h-full stroke-black" />
				{:else}
					<Icon icon="Minus" iconClass="w-full h-full stroke-white" />
				{/if}
			{:else}
				<Icon icon="PlusLucide" iconClass="w-full h-full stroke-black" />
			{/if}
			{#if $isExpanded}
				<div class="absolute w-px h-[30px] top-[100%] border-l border-black left-1/2" />
			{/if}
		</button>

		{#if $canExpand && $isExpanded}
			<div class="absolute w-px h-1/2 bottom-[0%] border-l border-black left-1/2" />
		{/if}

		{#if depth == 1}
			<div class="absolute w-px h-full bottom-[0%] border-l border-black left-1/2" />
			<TreeShape isExpanded={$isExpanded} style="full" {depth} />
		{/if}
	</div>
{:else}
	<!-- DEPTH -->
	{#if depth > 0}
		<div class="absolute w-px h-full bottom-[0%] border-l border-black left-1/2" />
	{/if}
	{#if depth == 2}
		<TreeShape isExpanded={$isExpanded} style="sub" {depth} />
	{/if}
{/if}

<style lang="postcss">
	.spacer {
		width: calc(var(--depth) * 16px);
	}
	.exp {
		@apply absolute w-full h-full inset-0 flex items-center justify-center;
		transition: 1s all;

		&.end {
			@apply justify-end;
		}
	}

	button {
		z-index: 10;
		right: 0px;
	}
</style>
