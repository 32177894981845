import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-sort"
export default class extends Controller {
  static targets = [ "selector" ];

  navigate() {
    let current_origin = window.location.origin;
    let current_pathname = window.location.pathname;
    let search_params = new URLSearchParams(window.location.search);

    search_params.delete("order")
    search_params.delete("dir");

    // ensure parameters read from the DOM are safe by escaping them
    const order = encodeURIComponent(
      this.selectorTarget.selectedOptions[0].getAttribute("data-order")
    );
    const dir = encodeURIComponent(
      this.selectorTarget.selectedOptions[0].getAttribute("data-dir")
    );

    let sorted_url = current_origin + current_pathname + "?" + search_params + "&order=" + order + "&dir=" + dir;
    window.location.href = sorted_url;
  }
}
