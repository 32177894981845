<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';

	const dispatch = createEventDispatcher();

	export let title = '';
	export let text = '';
	export let heights: any;
	export let status = 'default';

	export let index: number;
	export let stack: number;
	export let isHovering: boolean = false;
	export let shadow = true;

	export let position: string = 'top-right';

	let nodeHeight: number;
	let isFront: boolean = false;
	let isOpen: boolean = false;

	let item: HTMLDivElement;

	let gap = 10;

	let invert = -1;

	function getPosition(pos: string) {
		switch (pos) {
			case 'top-left':
				invert = 1;
				return 'top-5 left-5';
			case 'top-right':
				invert = 1;
				return 'top-5 right-5';
			case 'bottom-left':
				invert = -1;
				return 'bottom-5 left-5';
			case 'bottom-right':
				invert = -1;
				return 'bottom-5 right-5';
			default:
				return 'bottom-5 right-5';
		}
	}

	function toastsHeightBefore(heightsArray: any) {
		const thb = heightsArray.reduce((prev: any, curr: any, reducerIndex: any) => {
			if (reducerIndex >= index) {
				return prev;
			}

			return prev + curr + gap;
		}, 0);

		return thb;
	}

	$: previousHeight = toastsHeightBefore(heights);

	$: firstHeight = heights[0] ? heights[0] : 66;

	$: index, setHeights(item);

	function setHeights(node: HTMLDivElement) {
		if (node) {
			dispatch('height', {
				height: nodeHeight,
				index: index
			});
		}
	}
</script>

<div
	in:fly|local={{ y: -invert * 50, duration: 400 }}
	out:fly|local={{ y: invert * 10, duration: 300 }}
	bind:this={item}
	data-front={isFront}
	data-state={isOpen ? 'open' : 'closed'}
	data-hovering={isHovering}
	data-hidden={index > 3}
	class="in pointer-events-auto {getPosition(position)} {status}"
	class:shadowOn={shadow}
	style="
	--y:translateY({invert * (index * 10)}px);
	--index:{index}; z-index:{stack};
	--offset:calc({invert} * {previousHeight}px);
	 --gap: calc(1px * {gap});
	 --shift: calc({-invert}px * {gap + 4} * {index ? 1 : 0});
	 --opacity: calc(1 - {index} * 0.2);
	 --contentOpacity: {index ? 0 : 1};
	 --firstH:{firstHeight}px;
	 "
>
	<div class="in--bg">
		<!--  -->
	</div>
	<div class="in--wrap">
		<div bind:clientHeight={nodeHeight} class="in--content">
			{#if title && title.length}
				<div class="in--title">
					{title}
				</div>
			{/if}
			<div class="in--text">
				{text}
			</div>
		</div>
	</div>
</div>

<style lang="postcss">
	.in {
		---y: translateY(var(--offset));
		@apply absolute cursor-default;
		@apply w-[260px] text-sm rounded-lg border;
		@apply text-neutral-900 bg-neutral-100 border-neutral-300;
		transform-origin: center;
		transform: var(--y) scale(calc(-1 * var(--index) * 0.05 + 1));
		transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

		&.shadowOn {
			box-shadow: 0 2px 10px #0000001a;
		}

		&.bottom-right {
			@apply bottom-5 right-5;
		}
		&.top-left {
			@apply bottom-5 left-5;
		}

		&--content {
			transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
			@apply p-3;
		}

		&:hover {
			@apply bg-neutral-200;

			.in--text {
				@apply opacity-70;
			}
		}

		&.default {
			/*  */
		}

		&.dark {
			@apply bg-neutral-900 text-neutral-100 border-neutral-600;

			&:hover {
				@apply bg-neutral-800;
			}
		}

		&.success {
			@apply bg-green-400 text-green-400 border-green-400;

			&:hover {
				@apply bg-green-400;
			}
		}

		&.warning {
			@apply bg-yellow text-yellow border-yellow;

			&:hover {
				@apply bg-yellow;
			}
		}

		&.error {
			@apply bg-red text-red border-red;

			&:hover {
				@apply bg-red;
			}
		}

		&.info {
			@apply bg-sky-blue text-sky-blue border-sky-blue;

			&:hover {
				@apply bg-sky-blue;
			}
		}

		&[data-hidden='true'] {
			@apply hidden;
		}

		&[data-hovering='true'] {
			transform: var(---y) scale(calc(1));

			.in--content {
				transform: translateY(0);
				opacity: 1;
			}
		}

		&[data-hovering='false'] {
			.in--wrap {
				height: var(--firstH);
			}

			.in--content {
				transform: translateY(var(--shift));
				opacity: var(--contentOpacity);
			}
		}

		&--wrap {
			@apply overflow-hidden;
			transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
		}

		&:before {
			content: '';
			@apply absolute w-full left-0;
			top: -10px;
			height: 10px;
		}

		&--text {
			@apply opacity-50 transition-all;
		}
	}
</style>
