<script lang="ts">
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { numberWithCommas, toHoursAndMinutes } from '$lib/utils';

  export let value: string = 'Loading...';
  export let description: string = '';
  export let title: string = 'Total Content Views';
  export let isTime: boolean = false;
</script>

<div
  class="relative flex gap-4 flex-col h-full min-h-[100px] border border-neutral-200 border-opacity-60 bg-white rounded-xl p-4"
>
  <Tooltip.Root>
    <Tooltip.Trigger>
      <p class="w-full text-left text-xs uppercase opacity-50">{title}</p>
    </Tooltip.Trigger>
    {#if description !== ''}
      <Tooltip.Content>
        <p>{description}</p>
      </Tooltip.Content>
    {/if}
  </Tooltip.Root>
  {#if value === 'Loading...'}
    <p class="text-2xl font-normal">{value}</p>
  {:else if isTime}
    <p class="text-2xl font-normal">{toHoursAndMinutes(value)}</p>
  {:else}
    <p class="text-2xl font-normal">{numberWithCommas(value)}</p>
  {/if}
</div>
