<script lang="ts">
  import { createEventDispatcher } from 'svelte';
	import * as AlertDialog from '$comp/ui/alert-dialog/index';
	import { Button } from '$comp/ui/button';
  import { selectedRowCount, checkedRows } from './stores';

  let status: 'successfullyRemoved' | 'failed' = 'successfullyRemoved';

  const dispatch = createEventDispatcher();

  let usersExpiredCount = 0;

  async function bulkRemoveUser() {
    usersExpiredCount = Object.entries($checkedRows).length;
    for (const [key] of Object.entries($checkedRows)) {
      try {
        const response = await fetch(`/users/${key}`, {
          method: "PUT",
          credentials: "same-origin",
          headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({"expire": true}),
        });

        if (response.ok) {
          status = 'successfullyRemoved';
        } else {
          status = 'failed';
        }
      } catch (error) {
        console.error('Error expiring user', error);
      }
    };
    dispatch('remove-user');
	}
</script>

<AlertDialog.Root>
	<AlertDialog.Trigger asChild let:builder>
		<Button
      builders={[builder]}
      variant="outline"
      disabled={$selectedRowCount == 0 ? true : false}
      on:click={bulkRemoveUser}
    >Bulk Expire {$selectedRowCount > 0 ? "(" + $selectedRowCount + ")" : ''}</Button>
	</AlertDialog.Trigger>
	<AlertDialog.Content>
		<AlertDialog.Header>
			<AlertDialog.Title>Expire Users</AlertDialog.Title>
			<AlertDialog.Description>
        {#if status == "successfullyRemoved"}
          <div class="flex flex-col gap-4 pt-6">
            {usersExpiredCount} users have been successfully expired.
          </div>
        {:else if status == "failed"}
          <div class="flex flex-col gap-4 pt-6">
            <p>Unable to expire users.</p>
          </div>
        {/if}
			</AlertDialog.Description>
		</AlertDialog.Header>
		<AlertDialog.Footer>
      <AlertDialog.Action>Close</AlertDialog.Action>
		</AlertDialog.Footer>
	</AlertDialog.Content>
</AlertDialog.Root>
