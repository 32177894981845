<svelte:options customElement={{ tag: "bt-modal", shadow: "none" }} />

<script lang="ts">
  import { modalStore } from '$shared/modal/modalStore';
  import ModalToggle from '$shared/modal-toggle/ModalToggle.svelte';

  export let modalTitle = '';
  export let closeButtonText = 'Close';

  export let width: 'default' | 'wide' = 'default';

  $: modalName = $modalStore.modalName
</script>

{#if modalName}
  <div class="modal__outer">
    <ModalToggle closesModal modalToToggle={modalName} design="fullscreen"></ModalToggle>
    <div class="modal__inner {width}">
      <h2 class="modal__title">{modalTitle}</h2>
      <slot name="modal-contents"></slot>
      <div class="modal-control-bar">
        <ModalToggle design="action-button-text" closesModal modalToToggle={modalName} buttonClasses="grow-0">
          {closeButtonText}
        </ModalToggle>
        <slot name="modal-control-bar"></slot>
      </div>
    </div>
  </div>
{/if}

<style lang="postcss">
  :global(.modal-control-bar button[type="button"]) {
    flex-grow: 0;
  }
  .modal__outer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 50;
    top: 0;
    left: 0;
  }
  .modal__inner {
    z-index: 50;
    position: absolute;
    max-width: calc(100vw - 8rem);
    background: white;
    padding: theme("padding.3");
    border-radius: theme("borderRadius.lg");
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .modal__inner.default {
    width: 500px;
  }
  .modal__inner.wide {
    width: theme("screens.sm");
  }
  @media (max-width: theme("screens.sm")) {
    .modal__inner.default {
      max-width: calc(100vw - 2rem);
    }
    .modal__inner.wide {
      max-width: calc(100vw - 4rem);
    }
  }
  .modal-control-bar {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
  }
  .modal__title {
    font-weight: bold;
    font-size: theme("fontSize.3xl");
    margin-bottom: theme("margin.4");
    margin-top: 0;
  }
</style>