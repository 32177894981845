<script lang="ts">
  import { Line } from 'svelte-chartjs';

  import {
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip
  } from 'chart.js';

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Filler,
    CategoryScale,
    Colors
  );

  type LineChartData = {
    activity_date: string;
    views: number;
    learners: number;
  };

  export let data:LineChartData[] = [];
  export let title = 'Title';

  let brandSkyBlue = "#1C9FFE";
  let brandSpaceBlue = "#021B87";

  let defaultDatasetOptions = {
    fill: true,
    borderJoinStyle: 'bevel',
    pointBorderWidth: 10,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10
  };
</script>

<Line
  data={{
    labels: data.map((row) => {
      let date = new Date(row.activity_date);
      // get the month and format it to be 2 digits
      let month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
      // get the day and format it to be 2 digits
      let day = ('0' + date.getUTCDate()).slice(-2);
      // return the US-formatted date, e.g. 01/01
      return `${month}/${day}`;
    }),
    datasets: [
      {
        label: 'Content Views',
        data: data.map((row) => row.views),
        borderColor: brandSkyBlue,
        pointBackgroundColor: brandSkyBlue,
        backgroundColor: brandSkyBlue + '20',
        ...defaultDatasetOptions
      },
      {
        label: 'Learners',
        data: data.map((row) => row.learners),
        borderColor: brandSpaceBlue,
        pointBackgroundColor: brandSpaceBlue,
        backgroundColor: brandSpaceBlue + '20',
        pointStyle: 'triangle',
        ...defaultDatasetOptions
      }
    ]
  }}
  width={100}
  height={35}
  options={{
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        boxPadding: 4,
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
        enabled: true,
        intersect: false,
        callbacks: {
          title: function (context) {
            let title = context[0].label;
            if (title) {
              let date = new Date(title);
              return date.toLocaleString('default', { month: 'long', day: 'numeric' });
            }
          }
        }
      },
      colors: {
        enabled: false
      },
      title: {
        display: true,
        text: title,
        align: 'start'
      }
    }
  }}
/>
