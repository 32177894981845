<svelte:options customElement={{ tag: "bt-action-button", shadow: "none", }} />

<script lang="ts">
  export let buttonClasses = '';
  export let buttonStyles = '';
  export let buttonEl = null;
  export let design = '';
  export let isDisabled = false;
  export let buttonType = 'button'
</script>

<button 
  type={buttonType}
  class="action-button {buttonClasses} {design}"
  style={buttonStyles}
  on:click
  bind:this={buttonEl}
  disabled={isDisabled}
>
  <slot></slot>
</button>

<style>
  :global(.action-button svg) {
    flex-shrink: 0;
    margin-left: .5rem;
  }
  button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: .5rem;
    padding: .5rem 1rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    font-family: "ibm-plex-sans";
    font-size: 16px;
    line-height: 1.5;
    border-width: 1px;
    border-radius: 1px;
  }
  button:has(:nth-child(2)) {
    justify-content: space-between;
  }
  button.secondary {
    background: theme("colors.white");
    color: theme("colors.neutral.900");
    &:hover {
      background: theme("colors.neutral.900");
      color: theme("colors.white");
      border-color: theme("colors.neutral.900");
    }
  }
  button.primary {
    background: theme("colors.neutral.900");
    color: theme("colors.white");
    border-color: theme("colors.neutral.900");
    &:hover {
      background: theme("colors.white");
      color: theme("colors.neutral.900");
    }
    &:disabled {
      background: theme("colors.neutral.500");
      color: theme("colors.neutral.300");
      border-color: theme("colors.neutral.500");
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  button.tertiary {
    background: transparent;
    color: theme("colors.neutral.500");
    border: none;
    &:hover {
      background: transparent;
      color: theme("colors.neutral.900");
    }
  }
  button.primary-small {
    background: theme("colors.neutral.900");
    color: theme("colors.white");
    border-color: theme("colors.neutral.900");
    font-weight: normal;
    font-size: theme('fontSize.sm');
    flex-grow: 0;
    border-radius: 6px;
    &:hover {
      background: theme("colors.white");
      color: theme("colors.neutral.900");
    }
    &:disabled {
      background: theme("colors.neutral.500");
      color: theme("colors.neutral.300");
      border-color: theme("colors.neutral.500");
      &:hover {
        cursor: not-allowed;
      }
    }
  }
</style>