<script lang="ts">
  export let isSelected: Boolean;
  export let rowId;
  export let rowStore;

  const handleChangeSelection = () => {
  	if (isSelected) {
  		rowStore.update((checkedRows: any) => {
  			checkedRows[rowId] = true;
  			return checkedRows;
  		})
  	} else {
  		 rowStore.update((checkedRows: any) => {
  			delete checkedRows[rowId]
  			return checkedRows;
  		})
  	}
  }
</script>

<input type="checkbox" bind:checked={isSelected} on:change={handleChangeSelection} />
