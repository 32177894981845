<svelte:options customElement={{ tag: "bt-badge", shadow: "none", }} />

<script lang="ts">
  export let design: 'positive' | 'neutral' | 'neutral-transparent' | 'transparent' | 'notice' = 'neutral';
  export let text: string;
  export let badgeClass = '';
</script>

<span class="badge {design} {badgeClass}">
  {text}
</span>

<style lang="postcss">
  .badge {
    height: 1.5rem;
    width: fit-content;
    line-height: 1;
    padding: .25rem .5rem;
    font-weight: normal;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: theme("fontSize.xs");
    font-weight: normal;
    flex-shrink: 0;
  }

  .badge.neutral {
    background-color: theme("colors.neutral.400");
    color: theme("colors.neutral.50");
  }
  .badge.neutral-transparent {
    backdrop-filter: blur(4px);
    background-color: rgba(theme("colors.neutral.900"), .3);
    color: theme("colors.neutral.50");
  }
  .badge.transparent {
    background-color: transparent;
    color: theme("colors.neutral.900")
  }
  .badge.positive {
    background-color: theme("colors.green.100");
    color: theme("colors.green.400");
    border-color: theme("colors.green.200")
  }
  .badge.notice {
    background-color: theme("colors.sky.500");
    color: theme("colors.sky.100");
  }

  .badge.inset {
    border: 1px solid;
  }

  :global(bt-badge) {
    display: flex;
    flex-wrap: nowrap;
    min-width: fit-content;
  }
</style>