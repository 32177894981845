<svelte:options customElement={{ tag: "bt-contextual-pagination", shadow: "none", }} />

<script lang="ts">
  export let previousLessonURL: string;
  export let nextLessonURL: string;
  export let parentLessons;
  export let previousLessonTitle: string;
  export let nextLessonTitle: string;

  let parentLessonsArray = parentLessons.split(',').map(url => url.trim())
</script>

<div class="contextual-pagination print:hidden">
  <div class="prev">
    {#if previousLessonURL}
      <a href={previousLessonURL} class="left-0 top-12 lg:-top-1 group">
        <svg xmlns="http://www.w3.org/2000/svg" class="p-2 mr-2 border w-9 h-9 border-neutral-900 bg-neutral-900 text-neutral-100 group-hover:bg-neutral-100 group-hover:text-neutral-900" viewBox="0 0 20 20" fill="currentColor">
          <path d="M8.445 14.832A1 1 0 0010 14v-2.798l5.445 3.63A1 1 0 0017 14V6a1 1 0 00-1.555-.832L10 8.798V6a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4z" />
        </svg>
        <span class="group-hover:underline">{previousLessonTitle}</span>
      </a>
    {/if}
  </div>
    <div class="circles">
      {#each parentLessonsArray as lessonURL}
        <a href={lessonURL} class="mx-0.5">
          {#if lessonURL === window.location.pathname}
            &#x25CF;
          {:else}
            &#x25CB;
          {/if}
        </a>
      {/each}
    </div>
    <div class="next">
    {#if nextLessonURL}
      <a href={nextLessonURL} class="left-auto right-0 top-12 lg:-top-1 group">
        <span class="group-hover:underline">{nextLessonTitle}</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="p-2 ml-2 border w-9 h-9 border-neutral-900 bg-neutral-900 text-neutral-100 group-hover:bg-neutral-100 group-hover:text-neutral-900" viewBox="0 0 20 20" fill="currentColor">
          <path d="M4.555 5.168A1 1 0 003 6v8a1 1 0 001.555.832L10 11.202V14a1 1 0 001.555.832l6-4a1 1 0 000-1.664l-6-4A1 1 0 0010 6v2.798l-5.445-3.63z" />
        </svg>
      </a>
    {/if}
  </div>
</div>

<style>
  .contextual-pagination {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    position: sticky;
    top: 0;
    z-index: 50;
    padding: theme("spacing.2") 0;
    border-bottom: 1px solid theme("colors.neutral.150");
    background-color: theme("colors.neutral.50");
  }
  @media (max-width: theme("screens.sm")) {
    .contextual-pagination {
      grid-template-columns: 1fr;
      font-size: theme("fontSize.sm");
      padding: theme("spacing.2") theme("spacing.6");
    }
  }
  @media (max-width: theme("screens.lg")) {
    .contextual-pagination {
      padding: theme("spacing.2") theme("spacing.8");
    }
  }

  .circles {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
  }
  .prev, .next {
    display: flex;
    align-items: center;
    & a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    & span {
      display: block;
    }
    & svg {
      display: block;
      flex-shrink: 0;
    }
  }
  .prev {
    justify-content: flex-start;
  }
  .next {
    justify-content: flex-end;
    text-align: right;
  }
  @media (max-width: theme("screens.sm")) {
    .prev, .next {
      justify-content: center;
    }
    .next {
      text-align: right;
    }
  }
</style>