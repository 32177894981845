<script>
  // Probably hand-edited from FontAwesome 5, v5.15.4?, "pencil-alt"
  export let iconClass = '';
</script>

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"
  class={iconClass}>
  <title>Edit</title>
  <path fill="currentColor"
    d="M13.6144 3.88365L12.3538 5.14425C12.2253 5.27277 12.0175 5.27277 11.889 5.14425L8.85367 2.10897C8.72515 1.98045 8.72515 1.77263 8.85367 1.64411L10.1143 0.383511C10.6256 -0.127837 11.4569 -0.127837 11.971 0.383511L13.6144 2.02694C14.1285 2.53828 14.1285 3.36957 13.6144 3.88365ZM7.77081 2.72696L0.590004 9.90772L0.0102901 13.2301C-0.0690105 13.6786 0.322023 14.0669 0.770482 13.9903L4.0929 13.4079L11.2737 6.2271C11.4022 6.09858 11.4022 5.89076 11.2737 5.76224L8.23841 2.72696C8.10715 2.59844 7.89933 2.59844 7.77081 2.72696ZM3.39287 9.29246C3.24247 9.14206 3.24247 8.90143 3.39287 8.75103L7.604 4.53993C7.7544 4.38953 7.99503 4.38953 8.14543 4.53993C8.29583 4.69032 8.29583 4.93096 8.14543 5.08135L3.9343 9.29246C3.7839 9.44286 3.54327 9.44286 3.39287 9.29246ZM2.40571 11.5922H3.71827V12.5848L1.95452 12.8938L1.10409 12.0433L1.41309 10.2796H2.40571V11.5922Z"
  />
</svg>