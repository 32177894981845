export const userTableColumnHeaders: any = {
   	email: 'Email',
  	full_name: 'Name',
  	updated_at: 'Last Activity',
  	status: 'Status',
  	expires_at: 'Expires At',
  	roles: 'Permissions'
  }

export const userTableTooltips: any = {
   	email: "User's email",
  	expires_at: "Expiration date of the user, if applicable",
  	full_name: "User's first and last name",
  	roles: "User's permissions on the site",
  	status: "User's status: Active, Expired, Hasn't Logged In",
  	updated_at: "User invitation date or most recent login"
  }

export const userTableFilterableColumns: string[] = ['email', 'full_name'];
