// Expects input of e.g. "Wednesday, Aug 21, 2024" -- anything
// parsable as a Date. Returns e.g. "12:00 AM 8/21/2024"
export function formatDateStringAsTimeAndDate(dateString: string) {
	const date = new Date(dateString);
	const formattedDate = date.toLocaleDateString('en', {
		year: 'numeric',
		month: '2-digit',
		day: 'numeric'
	});

	const formattedTime = date.toLocaleTimeString('en', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
	return `${formattedTime} ${formattedDate}`;
}

// Expects e.g. ".21", returns e.g. "21%""
export function formatDecimalAsPercentage(decimalString: string) {
	// early return if already in percent form to avoid e.g. "21%" => "2100%"
	if (decimalString.match(/\%/)) { return decimalString}
	const parsed = parseFloat(decimalString) * 100;
	return parsed.toFixed(2) + '%';
}