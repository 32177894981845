import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search-convert"
export default class extends Controller {
  static values = {
    searchjoyId: Number,
    searchItemId: String,
    searchItemClass: String,
  }

  convert() {
    if (!this.searchjoyIdValue) {
      return;
    }

    fetch(`/search/${this.searchjoyIdValue}/convert/`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        search_item_id: this.searchItemIdValue,
        search_item_class: this.searchItemClassValue,
      }), // body data type must match "Content-Type" header
    });
  }
}
