<script lang="ts">
	import * as AlertDialog from '$comp/ui/alert-dialog/index';
	import { Button } from '$comp/ui/button';
  import { selectedRowCount, checkedRows } from './stores';

  let status: 'successfullyInvited' | 'failed' = 'successfullyInvited';

  async function bulkResendInvite() {
    // for each checked item, send a PUT request to the backend to resend the email invite
    // ideally move up and run the handleClickResendInvite function from data-table-actions-users.svelte
    // In the interest of getting this out, punting on a better, DRY implementation for now
    for (const [key] of Object.entries($checkedRows)) {
      try {
        const response = await fetch(`/users/${key}`, {
          method: "PUT",
          credentials: "same-origin",
          headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({"resendInvite": true}),
        });

        if (response.ok) {
          status = "successfullyInvited";
        } else {
          status = "failed";
        }
      } catch (error) {
        console.error('Error removing user', error);
      }
    }
  }
</script>

<AlertDialog.Root>
	<AlertDialog.Trigger asChild let:builder>
		<Button
      builders={[builder]}
      variant="outline"
      disabled={$selectedRowCount == 0 ? true : false}
      on:click={bulkResendInvite}
    >Resend Emails {$selectedRowCount > 0 ? "(" + $selectedRowCount + ")" : ''}</Button>
	</AlertDialog.Trigger>
	<AlertDialog.Content>
		<AlertDialog.Header>
			<AlertDialog.Title>Resend Invitations</AlertDialog.Title>
			<AlertDialog.Description>
        {#if status == "successfullyInvited"}
          <div class="flex flex-col gap-4 pt-6">
            {$selectedRowCount} invitations have been resent.
          </div>
        {:else if status == "failed"}
          <div class="flex flex-col gap-4 pt-6">
            <p>Unable to resend email invitations.</p>
          </div>
        {/if}
			</AlertDialog.Description>
		</AlertDialog.Header>
		<AlertDialog.Footer>
      <AlertDialog.Action>Close</AlertDialog.Action>
		</AlertDialog.Footer>
	</AlertDialog.Content>
</AlertDialog.Root>
