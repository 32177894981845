<svelte:options customElement={{ tag: "bt-curation-publish-preview"}} />

<script lang="ts">
  import { modalStore } from '$shared/modal/modalStore';
  import Modal from '$shared/modal/Modal.svelte';

  let slotContainer: Element;

  $: modalName = $modalStore.modalName

  let curationDropdown = document.getElementById('curation-dropdown');

  $: {
    if (modalName === 'previewCuration') {
      document.querySelectorAll('.publish-preview-slot').forEach(slotEl => {
        slotEl.removeAttribute('hidden')
      });
      curationDropdown.classList.add('hidden');
    }
  }
</script>

{#if modalName === 'previewCuration'}
  <Modal modalTitle="Publish Curation" closeButtonText="Cancel" width="wide">
    <div class="card-and-warning" slot="modal-contents">
      <div class="preview-card" bind:this={slotContainer}>
        <slot name="contents"></slot>
      </div>
      <div class="warning-text">
        Before publishing, we recommend you check the following:
        <ul>
          <li>Your Curation has a title &amp; description.</li>
          <li>You have removed empty sections.</li>
          <li>All sections have titles &amp; descriptions.</li>
        </ul>
      </div>
    </div>
    <slot name="publish-button" slot="modal-control-bar"></slot>
  </Modal>
{/if}

<style>
  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 50;
    top: 0;
    left: 0;
    padding: theme("padding.2");
  }
  .card-and-warning {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .preview-card {
    width: 20rem;
    margin-bottom: 1rem;
  }

  .warning-text {
    flex-basis: 18rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
</style>