import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="course-class"
export default class extends Controller {
  static values = {
    anchor: String,
  };

  connect() {
    this.element["course_class"] = this;
  }

  reveal(anchor) {
    if (anchor === this.anchorValue) {
      setTimeout(() => {
        this.dispatch("hide");
        const ddController =
          this.application.getControllerForElementAndIdentifier(
            this.element,
            "dropdown"
          );
        ddController.show();
        window.scrollTo({ top: this.element.offsetTop, behavior: "smooth" });
      });
    }
  }
}
