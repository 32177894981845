<svelte:options customElement={{ tag: "bt-submit-button", shadow: "none", }} />

<script lang="ts">
	import ActionButton from '$shared/action-button/ActionButton.svelte';
	import Icon from '$shared/icons/Icon.svelte'

	export let text = 'Submit';
	export let design = 'primary';
	export let icon = '';
	export let buttonStyles = '';
	export let buttonClasses = '';
</script>

<ActionButton design={design} buttonType="submit" {buttonStyles} {buttonClasses}>
	<span>{text}</span>
	{#if icon}
		<Icon icon={icon} size="medium" />
	{/if}
</ActionButton>

<style>
	:global(bt-submit-button) {
		display: block;
	}
</style>