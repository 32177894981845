<script lang="ts">
  import { onMount } from 'svelte';
  import { dateStore } from '$comp/analytics/dateStore';
  import DataTableActions from '$lib/components/analytics/AnalyticsDataTableActions.svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { numberWithCommas, toHoursAndMinutes } from '$lib/utils';

  type Expert = {
    name: string
    imageUrl: string;
    viewTime: number;
    contentViews: number;
  };
  export let topExperts:Expert[] = [];

  let dateRange: string = '';

  onMount(() => {
    dateStore.subscribe(() => {
      dateRange = dateStore.getDateRangeDisplayOrEmpty()
    });
  })
</script>

<div class="flex justify-between items-center mb-4">
  <p class="font-bold text-neutral-500 text-sm">Top Experts</p>
  <span class="align-right text-sm text-neutral-500">{dateRange}</span>
</div>
<div
  class="relative overflow-x-auto overflow-y-auto border border-neutral-200 shadow-md sm:rounded-lg"
>
  <table class="w-full text-sm text-left text-neutral-800">
    <thead class="sticky top-0 text-sm bg-white border-b border-neutral-200">
      <tr class="select-none">
        <th scope="col" class="px-4 py-3 font-normal">
          <Tooltip.Root>
            <Tooltip.Trigger> Image </Tooltip.Trigger>
            <Tooltip.Content>
              <p>Image of the Expert</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 font-normal">
          <Tooltip.Root>
            <Tooltip.Trigger> Name </Tooltip.Trigger>
            <Tooltip.Content>
              <p>Name of the Expert</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 font-normal whitespace-nowrap">
          <Tooltip.Root>
            <Tooltip.Trigger> Video View Time </Tooltip.Trigger>
            <Tooltip.Content>
              <p>Total view time of this Expert</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 text-right font-normal whitespace-nowrap">
          <Tooltip.Root>
            <Tooltip.Trigger> Video Views </Tooltip.Trigger>
            <Tooltip.Content>
              <p>Number of video views for this Expert</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 font-normal"> Actions </th>
      </tr>
    </thead>
    <tbody>
      {#each topExperts as expert}
        <tr class="bg-white border-b border-neutral-200 last:border-b-0">
          <th scope="row" class="px-4 py-4 font-normal text-gray-900 whitespace-nowrap">
            <div class="w-12 h-12 rounded-full overflow-hidden">
              <img src="{expert.imageUrl}" alt="Expert headshot" />
            </div>
          </th>
          <th scope="row" class="px-4 py-4 font-normal text-gray-900 whitespace-nowrap">
            {expert.name}
          </th>
          <td class="px-4 py-4">
            {toHoursAndMinutes(expert.viewTime)}
          </td>
          <td class="px-4 py-4 text-right"> {numberWithCommas(expert.contentViews)} </td>
          <td class="px-4 py-4">
            <DataTableActions row={expert} />
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
