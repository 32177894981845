<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	let value: string = '';

	function init(el: any) {
		el.focus();
	}

	function handleSubmit(e: any) {
		e.preventDefault();
		e.stopPropagation();

		dispatch('newCurationSubmit', {
			value
		});
		value = '';
	}
</script>

<form on:submit={handleSubmit} class="relative">
	<input
		class="border w-full p-1 px-3 rounded
        focus:outline-none focus:border-black
        pr-16"
		type="text"
		bind:value
		use:init
		placeholder="New Curation"
	/>
	<div
		class="absolute w-full h-full pointer-events-none flex justify-end items-center top-0 left-0 px-2"
	>
		<button
			type="submit"
			value="Enter"
			disabled={!value.length}
			class="group border flex gap-1 {value.length > 0
				? 'border-green-400 text-green-400 hover:text-white hover:bg-green-400'
				: 'border-neutral-550 text-neutral-550'} text-xs px-2 py-px rounded pointer-events-auto transition-all"
		>
			<div class="w-3 relative">
				<svg
					class="absolute w-full h-3 left-0 top-1"
					width="100%"
					height="100%"
					viewBox="0 0 21 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						class={value.length > 0 ? 'transition-all fill-green-400 group-hover:fill-white' : 'fill-neutral-550'}
						d="M17.5 1.5V13H6.5V8L0 14.5L6.5 21V16H20.5V1.5C20.5 0.672 19.828 0 19 0C18.172 0 17.5 0.672 17.5 1.5Z"
						fill="black"
					/>
				</svg>
			</div>
			Enter
		</button>
	</div>
</form>
