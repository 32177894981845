import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["prompt"];
  static values = {
    debug: Boolean,
    completion: Number,
    language: String,
    scorm: Boolean,
    scorm_content_id: Number,
    scorm_content_type: String,
  };

  connect() {
    if (this.debugValue) console.log("[video_controller] connected");

    const controller = this;

    controller.lastLogged = -1;
    controller.loggedCompletion = false;
    controller.playerReady();
  }

  playerReady() {
    const controller = this;
    const player = controller.element;

    if (controller.debugValue) console.log("[video_controller] checking media-player");

    // at this point, the underlying component is mounted/ready,
    // but the video element might not be
    const instance = player.querySelector("media-player");
    if (!instance) {
      setTimeout(() => {
        controller.playerReady();
      }, 100);
      return;
    }

    if (controller.debugValue) console.log("[video_controller] player-ready");

    // listen for time updates on the underlying instance
    instance.addEventListener("time-update", function (event) {
      controller.progress(event);
    });

    // we don't want to log progress, because this is not a play event
    // instead, we just want to make sure that the first prompt is
    // displayed to the user, as it's normally set as time = 0
    controller.dispatch("prompt", { detail: { time: 0 } });

    if (controller.languageValue) {
      controller.forceCaptionOn(instance);
    }

    if (controller.debugValue) {
      console.log("[video_controller] player: ", player);
      console.log("[video_controller] controller: ", controller);
    }
  }

  progress(event) {
    const controller = this;
    const currentPosition = event.detail.currentTime;
    const videoElement = controller.element.querySelector("video");
    const percentComplete = Math.round(
      (currentPosition / videoElement.duration) * 100
    );

    controller.dispatch("prompt", {
      detail: { time: Math.floor(currentPosition) },
    });

    if (
      percentComplete !== undefined &&
      percentComplete !== null &&
      controller.lastLogged !== percentComplete
    ) {
      if (controller.debugValue)
        console.log(
          "[video_controller] logging percentComplete: ",
          percentComplete
        );

      controller.lastLogged = percentComplete;

      ahoy.track("videos.progress", {
        percent: percentComplete,
        id: controller.element.id,
      });
    }

    if (
      controller.scormValue &&
      controller.loggedCompletion !== true &&
      percentComplete >= controller.completionValue
    ) {
      controller.loggedCompletion = true;
      // send completion to LMS, by dispatching an event that the
      // scorm_controller will listen for
      document.dispatchEvent(
        new CustomEvent("scorm.complete", {
          detail: {
            content_id: controller.scormContentIdValue,
            content_type: controller.scormContentTypeValue,
          },
        })
      );
    }

    if (controller.debugValue) {
      console.log("[video_controller] progress event: ", event);
      console.log("[video_controller] progress controller: ", controller);
    }
  }

  forceCaptionOn(instance, tries = 0) {
    const controller = this;

    if (controller.debugValue) {
      console.log("[video_controller] captions requested: ", controller.languageValue);
    }

    const tracks = instance.textTracks;
    if ((!tracks || tracks.length <= 0) && tries <= 10) {
      if (controller.debugValue) {
        console.log("[video_controller] retrying captions");
      }
      setTimeout(() => {
        controller.forceCaptionOn(instance, tries + 1);
      }, 100);
      return;
    }
    if (controller.debugValue) {
      console.log("[video_controller] textTracks: ", tracks);
    }

    // first, see if we can find a track where the id (aka, the language code)
    // is an exact match to our internal ISO 2-letter code
    let caption = tracks.getById(controller.languageValue);
    if (caption) caption.mode = "showing";

    if (controller.debugValue) {
      if (caption) {
        console.log("[video_controller] captions found: ", caption);
      } else {
        console.log("[video_controller] captions not found: ", controller.languageValue);
      }
    }
  }
}
