import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

export default class extends Controller {
  static values = {
    options: Object,
  };

  connect() {
    const options = {
      ...this.defaultOptions,
      ...this.optionsValue,
    };
    this.swiper = new Swiper(this.element, options);
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 48,
        },
      },
      modules: [Navigation],
      navigation: {
        disabledClass: "!opacity-60 !cursor-auto",
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        afterInit: function(swiper) {
          swiper.el.classList.remove("hidden");
          swiper.navigation.prevEl[0].classList.remove("hidden");
          swiper.navigation.nextEl[0].classList.remove("hidden");
        }
      },
      slidesPerView: 1,
      spaceBetween: 24,
    };
  }
}
