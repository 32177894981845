import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js";

// Connects to data-controller="scorm"
export default class extends Controller {
  static values = { contentId: String, contentType: String };

  connect() {
    // we're only ever going to be sending one completion event for a given
    // piece of content
    this.completionSent = false;

    // if these are present, we want to send completion to LMS on connect/load
    if (this.contentIdValue && this.contentTypeValue) {
      this.sendCompletionToLMS(this.contentIdValue, this.contentTypeValue);
    }

    // otherwise, we want to listen for a "scorm.complete" event to send
    // completion to the LMS. the event will be triggered by the player and will
    // contain the content_id and content_type
    document.addEventListener("scorm.complete", (event) => {
      this.sendCompletionToLMS(
        event.detail.content_id, event.detail.content_type
      );
    });
  }

  sendCompletionToLMS(content_id, content_type) {
    if (this.completionSent) {
      return;
    }

    let success = false;

    console.log("[scorm_controller] Sending completion to LMS");

    try {
      window.parent.RXD.SetReachedEnd();
      success = true;
    } catch (e) {
      console.warn(
        "[scorm_controller] Error sending completion to LMS, trying postMessage: " + e.message
      );
      success = false;
      try {
        window.parent.postMessage("RXD.SetReachedEnd", "https://edge.bigthink.com");
        success = true;
      } catch (e) {
        console.error(
          "[scorm_controller] Error sending completion to LMS: " + e.message
        );
        success = false;
      }
    }

    if (success) {
      this.completionSent = true;
      ahoy.track("scorm.complete", {
        content_id: content_id,
        content_type: content_type
      });
    }
  }
}
