<script lang="ts">
  import { onMount } from 'svelte';

  import { getLocalTimeZone, today } from '@internationalized/date';

  import { Calendar as CalendarIcon } from 'radix-icons-svelte';

  import { dateStore } from '$comp/analytics/dateStore';
  import { Button } from '$comp/ui/button';
  import * as Popover from '$comp/ui/popover';
  import { RangeCalendar } from '$comp/ui/range-calendar';

  const timeZone = getLocalTimeZone();
  const yesterday = today(timeZone).subtract({ days: 1 });

  let value;
  let dateRangeDisplay = '';
  let popoverOpen = false;

  function updateRange(newValue) {
    dateStore.setRange(newValue);

    if (newValue?.start && newValue?.end) {
      popoverOpen = false;
    }
  }

  onMount(() => {
    dateStore.subscribe(() => {
      dateRangeDisplay = dateStore.getDateRangeDisplay();
      value = dateStore.getRange();
    });
  });
</script>

<div class="grid gap-2">
  <Popover.Root openFocus bind:open={popoverOpen} onOpenChange={(isOpen) => popoverOpen = isOpen}>
    <Popover.Trigger asChild let:builder>
      <Button
        variant="outline"
        class="w-[300px] justify-start text-left font-normal"
        builders={[builder]}
      >
        <CalendarIcon class="mr-2 h-4 w-4" />
        {dateRangeDisplay}
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-auto p-0">
      <RangeCalendar
        {value}
        initialFocus
        maxValue={yesterday}
        numberOfMonths={2}
        onValueChange={updateRange}
      />
    </Popover.Content>
  </Popover.Root>
</div>
