<script lang="ts">
  export let loading: number = 0;
</script>

{#if loading > 0}
  <div class="w-6 h-6 mt-1 flex justify-center items-center">
    <span class="loader" />
  </div>
{/if}

<style>
  /* LOADER */
  .loader {
    width: 18px;
    height: 18px;
    border: 3px solid #000000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
