<script>
	export let className = '';

	export let transparent = false;
</script>

<div class:transparent class="box-border bg-blur p-px rounded-xl {className}">
	<div class="inner box-border bg-white p-3 rounded-xl h-full">
		<slot />
	</div>
</div>

<style lang="postcss">
	.bg-neutral-800 {
		background-color: #f3f3f3;
	}
	.bg-blur {
		background-color: #f1f1f1;
	}

	.transparent {
		@apply bg-transparent p-0;

		.inner {
			@apply bg-transparent p-0;
		}
	}
</style>