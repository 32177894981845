export const analyticsTableFilterableColumns: string[] = [
  'email',
  'name',
  'object_name',
  'user_email',
  'user_name',
  'video_lesson_name'
];

export const analyticsTableColumnBanlist: string[] = [
  'avatar',
  'children',
  'id',
  'organization_id',
  'organization_name',
  'slug',
  'total_video_lessons_started',
  'total_video_lessons_completed',
  'url',
  'user_id',
  'video_id'
];