<svelte:options customElement={{ tag: "bt-modal-container", shadow: "none", }} />

<script lang="ts">
  import { modalStore } from '$shared/modal/modalStore';

  import AddToCurations from "$comp/curations/AddToCurations.svelte";
  import MockModal from "$shared/modal/mock/MockModal.svelte";

  $: modalName = $modalStore.modalName
</script>

<div>
  {#if modalName === 'addToCuration'}
    <AddToCurations {...$modalStore.modalData} />
  {/if}
  {#if modalName === 'mockModal'}
    <MockModal {...$modalStore.modalData} />
  {/if}
</div>