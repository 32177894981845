<svelte:options customElement={{ tag: "bt-pill-checkbox", shadow: "none", }} />

<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	import { Plus, X } from 'lucide-svelte';

	export let label: string = 'Option'
	export let name: string = 'name';
	export let accentColor: string = '#C8C8C8';
	export let design: 'outline' | 'solid' = 'outline';
	export let visualOnly: boolean = false;
	export let iconless: boolean = false;

	export let isChecked: boolean = false;
	export let isDisabled: boolean = false;

	const dispatch = createEventDispatcher();

	const handleChange = () => {
		dispatch('checkbox-changed', {
			label: label,
			isChecked: isChecked
		});
	}

	let pillClass: string = '';

	$: {
		if (visualOnly || design) {
			let classes: string[] = ['pill-checkbox'];
			if (visualOnly) { classes = [...classes, 'pill-checkbox--visual-only'] }
			if (iconless) { classes = [...classes, 'pill-checkbox--iconless'] }
			if (design === 'outline') { classes = [...classes, 'pill-checkbox--outline'] }
			if (design === 'solid') { classes = [...classes, 'pill-checkbox--solid'] }
			pillClass = classes.join(' ');
		}
	}

</script>

<div class={pillClass} style={`--accent-color: ${accentColor}`}>
	<label for="{label + '-' + name}">
		{label} 
		<div class="icon w-4 h-4" aria-hidden="true">
			<Plus class="w-4 h-4" />
		</div>
	</label>
	<input type="checkbox" bind:checked={isChecked} name={name} id="{label + '-' + name}" value={label} on:change={handleChange} disabled={isDisabled} aria-hidden={visualOnly}>
</div>

<style>
	.pill-checkbox {
		white-space: nowrap;
	}

	.pill-checkbox--visual-only {
		pointer-events: none;
		label, input {
			user-select: none;
			pointer-events: none;
			&:hover {
				cursor: default;
			}
		}
	}

	.pill-checkbox--iconless {
		& .icon {
			display: none;
		}
		label {
			padding-right: 10px;
		}
	}

	input[type="checkbox"] {
		appearance: none;
		width: 0px;
		height: 0px;
		margin: 0;
		background: none;
		border: 0;
		&:checked, &:focus {
			background: initial;
			border: initial;
			outline: initial;
			box-shadow: initial;
		}
	}

	label {
		display: inline-block;
		position: relative;
		min-height: 2rem;
		padding: 2px 2rem 2px 10px;
		border: 1px dashed theme("colors.neutral.600");
		border-radius: 1rem;
		user-select: none;
		cursor: pointer;
		background: white;
		transition: background 100ms ease-in-out, color 100ms ease-in-out, outline 100ms ease-in-out;
		white-space: normal;
		&:hover {
			background: theme("colors.neutral.100");
		}
		&:has(~ :focus) {
			box-shadow: inset 0 2px theme("colors.neutral.300"), inset -2px 0 theme("colors.neutral.300"), inset 0 -2px theme("colors.neutral.300"), inset 2px 0 theme("colors.neutral.300")
		}
		&:has(~ :checked) {
			border-style: solid;
			border-color: var(--accent-color);
			outline: 1px solid var(--accent-color);
		}

		&:has(~ :disabled) {
			background: theme('colors.neutral.100');
			color: theme('colors.neutral.400');
			border: 1px dotted theme("colors.neutral.400");
			cursor: not-allowed;
		}
	}

	.pill-checkbox--outline label {
		&:has(~ :checked) {
			border-style: solid;
			border-color: var(--accent-color);
			outline: 1px solid var(--accent-color);
		}
		&:has(~ :checked:focus) {
			outline: 1px solid theme("colors.neutral.400");
		}
	}

	.pill-checkbox--solid label {
		&:has(~ :checked) {
			background: var(--accent-color);
		}
		&:has(~ :checked:focus) {
			box-shadow: 0 2px theme("colors.neutral.500"), -2px 0 theme("colors.neutral.500"), 0 -2px theme("colors.neutral.500"), 2px 0 theme("colors.neutral.500")
		}
	}

	.icon {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: .5rem;
		margin: auto;
		transition: transform 50ms ease-in-out;
	}
	label:has(~ :checked) .icon {
		transform: rotate(45deg);
	}
</style>