<script>
  // HeroIcons 1.0.6, "check", outline
  export let iconClass = '';
</script>

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
  stroke-width="1.5" stroke="currentColor" class={iconClass}>
  <title>Check</title>
  <path stroke-linecap="round" stroke-linejoin="round"
    d="M5 13l4 4L19 7" />
</svg>