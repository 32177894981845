import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="course"
export default class extends Controller {
  connect() {
    if (window.location.hash) {
      console.log("course.connect() called with hash: " + window.location.hash);
      setTimeout(() => {
        this.travel({
          target: { hash: window.location.hash },
        });
      });
    }
  }

  travel(event) {
    try {
      console.log("course.travel() called with: " + event.target.hash);
      const classController = document.querySelector(
        event.target.hash
      ).course_class;
      classController.reveal(event.target.hash);
    } catch (_) {
      setTimeout(() => {
        this.travel({
          target: { hash: event.target.hash },
        });
      }, 50);
    }
  }
}
