// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// This can all probably be refactored to improve the readability

import ahoy from "ahoy.js";
ahoy.configure({
  eventsUrl: "/api/events" + window.location.search,
  visitsUrl: "/api/visits" + window.location.search,
})

import "@hotwired/turbo-rails";
import "@shoelace-style/shoelace";
import '@shoelace-style/shoelace/dist/themes/light.css';

import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
// const controllers = import.meta.globEager("../controllers/*_controller.js");
const controllers = import.meta.glob("../controllers/*_controller.js", {eager: true});
registerControllers(application, controllers)

// Import Svelte page layouts, views, and components
// import.meta.globEager("../**/*.svelte");
import.meta.glob(["../**/*.svelte", "!**.stories.svelte"], {eager: true});

// Import ViewComponent sidecar components
// import.meta.globEager("../../components/**/*_component.js");
import.meta.glob("../../components/**/*_component.js", {eager: true});

import Sortable from "stimulus-sortable";
import { Alert, Dropdown, Tabs } from "tailwindcss-stimulus-components";

application.register("alert", Alert);
application.register("dropdown", Dropdown);
application.register("sortable", Sortable);
application.register("tabs", Tabs);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Namespace for global variables/functions
window.BTPLUS ||= {}

// prevent the "content missing" message from being displayed
document.addEventListener("turbo:frame-missing", (event) => {
  event.preventDefault();
});

// When the page is loaded, find all links that point to 'edge.bigthink.com' and
// rewrite them to use relative URLs instead of absolute URLs. This is necessary
// because the ID team has used absolute URLs in some places, and we need to
// make sure that those links don't break when we're in staging or on the new
// platform. We need this to happen whether or not the page is loaded via Turbo.

// Some of the text content uses assets.edge.bigthink.com links to link to pdf downloads
// So we need to not apply the rewrite for those links
function rewriteEdgeLinks() {
  const links = document.querySelectorAll("a[href*='edge.bigthink.com']:not([href*='assets.edge.bigthink.com'])");

  links.forEach((link) => {
    const url = new URL(link.href);
    const path = url.pathname + url.search + url.hash;
    link.setAttribute("href", path);
  });
}

document.addEventListener("turbo:load", rewriteEdgeLinks);
document.addEventListener("DOMContentLoaded", rewriteEdgeLinks);

export { application };
