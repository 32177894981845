<svelte:options customElement={{ tag: "bt-ratings-button-set", shadow: "none", }} />

<script lang="ts">
  import RatingsButton from '$shared/ratings-button/RatingsButton.svelte'

  export let postURL = '';
  export let likePercentage = '';
  export let userLiked = false;
  export let userDisliked = false;
  
  const handleRating = (event: CustomEvent) => {
    if (!userLiked && !userDisliked) {
      if (event.detail === 'like') {
        userLiked = true;
      } else {
        userDisliked = true;
      }
    } else {
      userLiked = !userLiked;
      userDisliked = !userDisliked;
    }
  }
</script>

<div class="flex flex-shrink-0 space-x-1">
  <RatingsButton
    likeOrDislike="like"
    rated={userLiked}
    text={likePercentage}
    {postURL}
    on:rated={handleRating}
  />
  <RatingsButton
    likeOrDislike="dislike"
    rated={userDisliked}
    {postURL}
    on:rated={handleRating}
  />
</div>
