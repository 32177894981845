<svelte:options customElement={{ tag: "bt-analytics-dashboard-index", shadow: "none", }} />

<script lang="ts">
  import { onMount } from 'svelte';

  import { dateStore } from '$comp/analytics/dateStore';
  import AnalyticsCard from '$comp/analytics/AnalyticsCard.svelte';
  import Analytics from '$lib/layouts/Analytics.svelte';
  import Grid from '$comp/analytics/Grid.svelte';
  import PageHeader from '$comp/analytics/PageHeader.svelte';
  import MetricsCard from '$comp/analytics/MetricsCard.svelte';
  import LineChart from '$comp/analytics/visualizations/LineChart.svelte';
  import TopExperts from '$comp/analytics/dashboard/TopExperts.svelte';
  import TopCapabilities from '$comp/analytics/dashboard/TopCapabilities.svelte';

  let lineChartData = [];
  let topCapabilities = [];
  let topExperts = [];
  let topMetrics = {
    totalViewTime: "Loading...",
    totalContentViews: "Loading...",
    lessonsCompleted: "Loading...",
    learners: "Loading..."
  };

  export let organizationName = "Organization Name";
  export let lineChartDataUrl;
  export let topCapabilitiesUrl;
  export let topExpertsUrl;
  export let topMetricsUrl;
  export let startDate: string = '';
  export let endDate: string = '';

  async function fetchData(url, callback) {
    loading++;

    const fetchUrl = url + `?start_date=${startDate}&end_date=${endDate}`;
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    callback(data);

    loading--;
  }

  function fetchAllData() {
    fetchData(lineChartDataUrl, data => {
      lineChartData = data.data;
    });
    fetchData(topCapabilitiesUrl, data => {
      topCapabilities = data.data;
    });
    fetchData(topExpertsUrl, data => {
      topExperts = data.data;
    });
    fetchData(topMetricsUrl, data => {
      topMetrics = data.data;
    });
  }

  onMount(() => {
    dateStore.initialize(startDate, endDate);
    dateStore.subscribe(() => {
      startDate = dateStore.getStart();
      endDate = dateStore.getEnd();

      startDate && endDate && fetchAllData();
    });
  });

  $: loading = 0;
</script>

<Analytics>
    <PageHeader title="{organizationName}" {loading} />

  <Grid>
    <div class="col-span-full h-full grid grid-cols-2 md:grid-cols-4 gap-4">
      <MetricsCard value={topMetrics.totalViewTime} title="Time Spent Learning" description="The sum of video view time and course learning time for all learners over this time period." isTime />
      <MetricsCard value={topMetrics.totalContentViews} title="Total Content Views" description="The sum of video views and Course submodules accessed" />
      <MetricsCard value={topMetrics.lessonsCompleted} title="Lessons Completed" description="The number of lessons that learners have completed over this time period" />
      <MetricsCard value={topMetrics.learners} title="Learners" description="The number of unique learners that have accessed Big Think+ over this time period" />
    </div>

    <div class="col-span-full">
      <div class="bg-transparent p-0 box-border bg-blur p-px rounded-xl">
        <div class="inner box-border bg-white p-3 rounded-xl h-full relative" style="min-height: 20rem;">
          <LineChart title="Overview" data={lineChartData} />
        </div>
      </div>
    </div>

    <div class="col-span-full md:col-span-3">
      <AnalyticsCard>
        <TopExperts {topExperts} />
      </AnalyticsCard>
    </div>

    <div class="col-span-full md:col-span-3">
      <AnalyticsCard>
        <TopCapabilities topCapabilities={topCapabilities} />
      </AnalyticsCard>
    </div>
  </Grid>
</Analytics>
