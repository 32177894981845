<svelte:options customElement={{ tag: "bt-icon", shadow: "none" }} />

<script lang="ts">
  import icons from '$lib/components/shared/icons/icons';

  export let icon = '';
  export let iconClass = '';

  export let size: 'small' | 'medium' | 'auto' = 'auto';
</script>

<svelte:component this={icons[icon]} iconClass={`bt-icon ${size} ${iconClass}`} />

<style>
  :global(.bt-icon.small) {
    width: theme('size.4');
    height: theme('size.4');
  }
  :global(.bt-icon.medium) {
    width: theme('size.6');
    height: theme('size.6');
  }
</style>