<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import Badge from '$shared/badge/Badge.svelte';

	const dispatch = createEventDispatcher();

	export let curation: any;
	export let allCurations: any;
	export let i: number = 0;
	export let inCuration = curation.existing_id ? true : false;

	let loading = false;
	let loadingText = 'Adding...';

	function handleAddToCuration(e: Event) {
		if (!e.target) return;
		const target = e.target as HTMLInputElement;
		const curationId = e.currentTarget.getAttribute("data-id");
		loading = true;

		const ischecked = target.checked;
		if (ischecked) {
			loadingText = 'Adding...';
		} else {
			loadingText = 'Removing...';
		}
		setTimeout(() => {
			if (ischecked) {
				dispatch('check', {
					name: target.name,
          id: curationId
				});
			} else {
				dispatch('uncheck', {
					name: target.name,
          id: curationId,
					item_id: curation.existing_id
				});
			}
			loading = false;
		}, 600);
	}
</script>

<div class="flex justify-start items-center group">
	{#if allCurations.find(allCuration => allCuration.aasm_state === 'published')}
		<div class="w-[4.5rem] shrink-0">
			{#if curation.aasm_state === 'published'}
				<Badge design="positive" text="Published"/>
			{/if}
		</div>
	{/if}
	<label for="check-{i}" class="control control-checkbox relative flex gap-2 cursor-pointer ml-2 grow">
		<input
			on:input={handleAddToCuration}
			type="checkbox"
			bind:checked={inCuration}
			name={curation.name}
			id=check-{i}
			data-id={curation.id}
		/>
		{#if loading}
			<div class="w-6 h-6 flex justify-center items-center">
				<span class="loader" />
			</div>
			<span class="italic opacity-50">
				{loadingText}
			</span>
		{:else}
			<div class="control_indicator flex-shrink-0" />
			{curation.name}
		{/if}
	</label>
	<a
		href="/curations/{curation.id}"
		class="group-hover:opacity-90 opacity-0 transition-all text-xs flex-shrink-0">View →</a
	>
</div>

<style>
	/* LOADER */
	.loader {
		width: 18px;
		height: 18px;
		border: 3px solid #000000;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	/* CHECKBOX INPUT */
	.control input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	.control_indicator {
		position: relative;
		top: 2px;
		left: 0;
		height: 20px;
		width: 20px;
		background: #ffffff;
		border: 1px solid #000000;
		border-radius: 2px;
	}

	.control input:checked ~ .control_indicator {
		background: #5DBA3A;
	}
	.control:hover input:not([disabled]):checked ~ .control_indicator,
	.control input:checked:focus ~ .control_indicator {
		background: #5DBA3A;
	}
	.control input:disabled ~ .control_indicator {
		background: #ebebeb;
		opacity: 0.6;
		pointer-events: none;
	}
	.control_indicator:after {
		box-sizing: unset;
		content: '';
		position: absolute;
		display: none;
	}
	.control input:checked ~ .control_indicator:after {
		display: block;
	}
	.control-checkbox .control_indicator:after {
		left: 7px;
		top: 3px;
		width: 3px;
		height: 8px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
	.control-checkbox input:disabled ~ .control_indicator:after {
		border-color: #666666;
	}
</style>
