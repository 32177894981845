import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="togglebutton"
export default class extends Controller {
  static targets = ["button", "text"];
  static values = {
    putUrl: String,
    getUrl: String,
    trueText: String,
    falseText: String,
    booleanProperty: String
  }

  connect() {
    this.get(this.getUrlValue).then(booleanResponse => {
      this.textTarget.innerText = booleanResponse ? this.trueTextValue : this.falseTextValue;
    })
  }

  submit() {
    this.put(this.putUrlValue).then(() => {
      this.get(this.getUrlValue).then(booleanResponse => {
        this.textTarget.innerText = booleanResponse ? this.trueTextValue : this.falseTextValue;
      })
    })
  }

  async put(url) {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      credentials: 'same-origin',
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
  }

  async get(url) {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      credentials: 'same-origin',
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    } else {
      const data = await response.json();
      return data[this.booleanPropertyValue];
    }
  }
}
