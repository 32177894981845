<script lang="ts">
  import Modal from '$shared/modal/Modal.svelte';

</script>


<Modal modalTitle="Mock Modal" closeButtonText="Close">
  <svelte:fragment slot="modal-contents">
    <p>A mock modal for Storybook :)</p>
  </svelte:fragment>
</Modal>