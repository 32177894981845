import { writable } from 'svelte/store';

interface ModalInterface {
  modalName: string,
  modalData?: {}
}

const initialModalState: ModalInterface = {
  modalName: '',
  modalData: {}
}

const createModalStore = () => {
  const { subscribe, update } = writable(initialModalState);

  return {
    subscribe,
    setName: (modalName: string) => update((state) => ({ ...state, modalName})),
    setData: (modalData: {}) => update((state) => ({ ...state, modalData}))
  }
}

export const modalStore = createModalStore();