import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="content-card"
export default class extends Controller {
  static values = {
    searchjoyId: Number
  }

  convert() {
    if (!this.searchjoyIdValue) {
      return;
    }

    fetch(`/searchjoy/convert?id=${this.searchjoyIdValue}`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      }
    });
  }
}
