// src/stores/dateStore.ts
import { writable } from 'svelte/store';

import {
  DateFormatter,
  getLocalTimeZone,
  parseDate,
  today,
  type DateValue
} from '@internationalized/date';
import type { DateRange } from 'bits-ui';

const timeZone = getLocalTimeZone();
const df = new DateFormatter('en-US', { dateStyle: 'medium' });
const localDate = today(timeZone).subtract({ days: 1 });

const formatDate = (value: DateValue): string => {
  if (!value) return null;

  const yyyy = value.year;
  const mm = value.month.toString().padStart(2, '0');
  const dd = value.day.toString().padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

const parseDateValue = (dateString: string): DateValue => {
  return parseDate(dateString);
};

const getInitialDate = (date: string, isStart = false): DateValue => {
  if (date) {
    return parseDate(date, timeZone);
  } else if (isStart) {
    return localDate.subtract({ days: 30 });
  } else {
    return localDate;
  }
};

const datePickerStore = writable<DateRange>({ start: null, end: null });

let startDate: DateValue = null;
let endDate: DateValue = null;
let range: DateRange = { start: null, end: null };

datePickerStore.subscribe(store => {
  range = store;
  startDate = store.start;
  endDate = store.end;
});

export const dateStore = {
  subscribe: datePickerStore.subscribe,
  initialize: (startDateString?: string, endDateString?: string) => {
    const startDate = startDateString ? parseDateValue(startDateString) : getInitialDate('', true);
    const endDate = endDateString ? parseDateValue(endDateString) : getInitialDate('');
    datePickerStore.set({ start: startDate, end: endDate });
  },
  getDefaultStart: (): string => {
    return formatDate(getInitialDate('', true));
  },
  getDefaultEnd: (): string => {
    return formatDate(getInitialDate(''));
  },
  setStart: (dateString: string) => {
    datePickerStore.update(store => {
      store.start = parseDateValue(dateString);
      return store;
    });
  },
  setEnd: (dateString: string) => {
    datePickerStore.update(store => {
      store.end = parseDateValue(dateString);
      return store;
    });
  },
  getStart: (): string => {
    return formatDate(startDate);
  },
  getEnd: (): string => {
    return formatDate(endDate);
  },
  setRange: (range: DateRange) => {
    datePickerStore.set(range);
  },
  getRange: (): DateRange => {
    return range;
  },
  getDateRangeDisplay: (): string => {
    if (!range.start || !range.end) return 'Select Date Range';

    const startFormatted = df.format(range.start.toDate(timeZone));
    const endFormatted = df.format(range.end.toDate(timeZone));

    return `${startFormatted} – ${endFormatted}`;
  },
  getDateRangeDisplayOrEmpty: (): string => {
    if (!range.start || !range.end) return '';

    const startFormatted = df.format(range.start.toDate(timeZone));
    const endFormatted = df.format(range.end.toDate(timeZone));

    return `${startFormatted} – ${endFormatted}`;
  }
};
