import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deletion"
export default class extends Controller {
  static values = {
    favoritors: Number
  }

  delete(event) {
    const msg = event.params.msg;
    let confirmed = false;

    if (msg == "item") {
      confirmed = confirm("Are you sure you want to remove this item from your curation?");
    } else if (this.favoritorsValue > 0) {
      confirmed = confirm("Deleting this curation will make it unavailable for followers. Are you sure you want to delete this curation?");
    } else {
      confirmed = confirm("Are you sure you want to delete this curation?");
    }

    if(!confirmed) {
      event.preventDefault();
    }
  }
}
