<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  // @ts-ignore
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  // @ts-ignore
  import { Button } from '$lib/components/ui/button';
  import Icon from '$shared/icons/Icon.svelte';

  const dispatch = createEventDispatcher();

  export let id: number;
  export let isFirst:boolean;
  export let isLast: boolean;
  export let curatable: number;

  export let moveTopLabel = 'Move Section To Top';
  export let moveUpLabel = 'Move Section Up';
  export let moveDownLabel = 'Move Section Down';
  export let moveBottomLabel = 'Move Section To Bottom';
  export let deleteLabel = 'Delete Section';

  function handleMoveTop() {
    dispatch('moveTop', {
      id: id,
      curatable: curatable
    });
  }
  function handleMoveUp() {
    dispatch('moveUp', {
      id: id,
      curatable: curatable
    });
  }
  function handleMoveDown() {
    dispatch('moveDown', {
      id: id,
      curatable: curatable
    });
  }
  function handleMoveBottom() {
    dispatch('moveBottom', {
      id: id,
      curatable: curatable
    });
  }

  function handleDelete() {
    dispatch('delete', {
      id: id,
      curatable: curatable
    });
  }
</script>

<div class="shrink-0">
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button variant="ghost" builders={[builder]} size="icon" class="relative w-8 h-8 p-0">
        <span class="sr-only">Open move section menu</span>
        <Icon icon="ArrowUpDown" size="small" />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      <DropdownMenu.Group>
        <DropdownMenu.Item class="cursor-pointer {isFirst ? 'pointer-events-none opacity-50' : ''}" on:click={handleMoveTop}>
          <Icon icon="ChevronsUp" size="small" iconClass="mr-1" />{moveTopLabel}
        </DropdownMenu.Item>
        <DropdownMenu.Item class="cursor-pointer {isFirst ? 'pointer-events-none opacity-50' : ''}" on:click={handleMoveUp}>
          <Icon icon="ChevronUp" size="small" iconClass="mr-1" />{moveUpLabel}
        </DropdownMenu.Item>
        <DropdownMenu.Item class="cursor-pointer {isLast ? 'pointer-events-none opacity-50' : ''}" on:click={handleMoveDown}>
          <Icon icon="ChevronDown" size="small" iconClass="mr-1" />
          {moveDownLabel}
        </DropdownMenu.Item>
        <DropdownMenu.Item class="cursor-pointer {isLast ? 'pointer-events-none opacity-50' : ''}" on:click={handleMoveBottom}>
          <Icon icon="ChevronsDown" size="small" iconClass="mr-1" />{moveBottomLabel}
        </DropdownMenu.Item>
      </DropdownMenu.Group>
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  <Button variant="ghost" size="icon" class="relative w-8 h-8 p-0" on:click={handleDelete}>
    <Icon icon="Trash2" size="small" iconClass="mr-1" />
  </Button>
</div>
