<svelte:options customElement={{ tag: "bt-radio-group", shadow: "none", }} />

<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	export let options: any[] = [];
	export let selected: any = null;

	const dispatch = createEventDispatcher();

	const handleChange = () => {
		dispatch('radio-changed', selected);
	}
</script>

<div class="radio-group">
	{#each options as option}
		<label class="radio-item">
	    <input type="radio" value={option.value} name="radio" bind:group={selected} on:change={handleChange} />
	    {option.label}
	  </label>
	{/each}
</div>

<style>

.radio-item {
  font-family: system-ui, sans-serif;
  font-size: theme('fontSize.base');  
  line-height: 1;
  display: grid;
  grid-template-columns: theme('spacing.4') auto;
  gap: 0.5em;
}

.radio-item + .radio-item {
  margin-top: theme('spacing.4');
}

.radio-item:focus-within {
  color: theme('colors.neutral.700');
}

.radio-item:hover {
	cursor: pointer;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: theme('spacing.4');
  height: theme('spacing.4');
  border: theme('borderWidth.2') solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
  &:hover {
  	cursor: pointer;
  }
}

input[type="radio"]::before {
  content: '';
  width: theme('spacing.2');
  height: theme('spacing.2');
  border-radius: 50%;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  box-shadow: inset theme('spacing.4') theme('spacing.4') theme('colors.sky-blue');
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:hover::before {
  transform: scale(.5);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: 1px solid theme('colors.sky-blue');
  outline-offset: 1px;
}
</style>