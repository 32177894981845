<script lang="ts">
  import icons from '$lib/components/shared/icons/icons';
  import Icon from '$shared/icons/Icon.svelte';

  const sizes = [
    { val: 'small', text: 'Small' },
    { val: 'medium', text: 'Medium' },
    { val: 'auto', text: 'Auto' }
  ]

  let selectedSize = sizes[1];

  const handlePointerEnter = (e) => {
    e.srcElement.querySelector('.icon-copyboard').removeAttribute('hidden');
  }
  const handlePointerLeave = (e) => {
    e.srcElement.querySelector('.icon-copyboard').setAttribute('hidden', true);
  }
  const copy = async(options) => {
    let button = event?.srcElement;
    let stringToCopy = '';
    if (options.kind === 'name') {
      stringToCopy = options.value;
    }
    if (options.kind === 'svelte') {
      stringToCopy = `<Icon icon="${options.value}" size="${selectedSize.val}"/>`
    }
    if (options.kind === 'erb') {
      stringToCopy = `<bt-icon icon="${options.value}" size="${selectedSize.val}"></bt-icon>`
    }

    try { 
      await navigator.clipboard.writeText(stringToCopy);
    } catch (error) {
      console.error('error.message');
      return;
    } finally {
      if (button) {
        button.style.background = '#c9ffc1';
        setTimeout(()=>{ button.style.background='' }, 100)
      }
    }
    
  }
</script>

<label class="text-sm">
  Icon Size
  <select bind:value={selectedSize} class="ml-2 text-sm">
    {#each sizes as size}
      <option value={size}>
        {size.text}
      </option>
    {/each}
  </select>
</label>

<hr class="my-6" />
<span>Hover over icon to copy</span>
<hr class="my-6" />

<div class="icon-grid">
  {#each Object.keys(icons) as iconName}
    <div class="icon" on:pointerenter={handlePointerEnter} on:pointerleave={handlePointerLeave}>
      {iconName}
      <Icon icon={iconName} size={selectedSize.val}/>
      <div class="icon-copyboard" hidden>
        <button type="button" on:click={() => { copy({ value: iconName, kind: 'name' }) } }>
          Name
        </button>
        <button type="button" on:click={() => { copy({ value: iconName, kind: 'svelte' }) } }>
          For Svelte
        </button>
        <button type="button" on:click={() => { copy({ value: iconName, kind: 'erb' }) } }>
          For ERB
        </button>
      </div>
    </div>
  {/each}
</div>

<style>
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .icon {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid theme('colors.neutral.500');
    margin: theme('space.2');
    font-size: theme('fontSize.xs');
    position: relative;
  }
  .icon-copyboard {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: theme('colors.neutral.100');
    &[hidden] {
      display: none;
    }
    button {
      display: block;
      flex-basis: 33.3%;
      align-content: center;
      text-align: center;
    }
    button:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

</style>