import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch"
export default class extends Controller {
  static targets = ["initial", "result"];

  connect() {
    addEventListener("pageshow", () => {
      this.element.classList.remove("pointer-events-none");
      this.initialTarget.classList.remove("hidden");
      this.resultTarget.classList.add("hidden");
    });
  }

  toggle() {
    this.element.classList.add("pointer-events-none");
    this.initialTarget.classList.add("hidden");
    this.resultTarget.classList.remove("hidden");
  }
}
