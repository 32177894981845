
<script lang="ts">
	export let style: 'none' | 'full' | 'sub' | 'open' = 'none';
	export let depth: number = 0;
</script>

{#if style == 'full'}
	<!-- depth 1 -->
	<div
		class="top-0 absolute border border-black border-t-0 border-r-0 rounded-bl-md w-[32px] h-1/2 pointer-events-none"
	/>
{:else if style == 'sub'}
	<!-- depth 2 -->
	<div style:--depth={depth} class="exp">
		<div
			class="top-0 absolute border border-black border-t-0 border-r-0 rounded-bl-md w-[8px] h-1/2 right-0"
		/>
		<div
			class="top-0 absolute border border-black border-t-0 border-r-0 border-b-0 w-[8px] h-full right-0"
		/>
	</div>
{:else if style == 'open'}
	<!-- depth 3 -->
{:else}
	<!-- depth 0 -->
{/if}

<style lang="postcss">
	.exp {
		@apply absolute w-full h-full inset-0 flex items-center justify-center;
		transition: 1s all;
	}
</style>
