<svelte:options customElement={{ tag: "bt-collapsible-trigger" }} />

<script lang="ts">
  export let targetID: string = "";
  export let isExpanded: boolean = false;

  const handleClick = () => {
    const target = document.querySelector(`#${targetID}`)
    if (target) {
      isExpanded = !isExpanded;
      target.toggleAttribute('hidden');
    }
  }
</script>

<button on:click={handleClick} aria-controls={targetID} aria-expanded={isExpanded}>
  <slot />
</button>

<style>
  button {
    all: inherit;
    cursor: pointer;
  }
</style>