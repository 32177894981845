const iconFiles = import.meta.glob('$shared/icons/**/*.svg.svelte', { eager: true});

const icons = {};

for (const path in iconFiles) {
  let iconName;

  const iconMatches = path.match(/(?:iconFiles\/)(.+).svg.svelte/);
  if (iconMatches && iconMatches[1]) {
    iconName = iconMatches[1];
    icons[iconName] = iconFiles[path].default;
  }
}

export default icons;