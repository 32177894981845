<svelte:options customElement={{ tag: "bt-clipboard-button", shadow: "none"}} />

<script lang="ts">
  import { onMount } from 'svelte';
  import { debounce } from '$shared/lib/debounce';
  import ActionButton from '$shared/action-button/ActionButton.svelte';
  import Icon from '$shared/icons/Icon.svelte';

  export let text: string = 'Copy';
  export let successText: string = 'Copied!';
  export let toCopy: string = '';
  export let buttonClasses = '';
  export let design: 'primary' | 'secondary' | 'tertiary' = 'secondary';
  
  let buttonEl: DOMElement;
  let buttonText: string = text;
  let errorText = 'Copy failed';
  let justCopied: boolean = false;
  let justErrored: boolean = false;

  let minWidth: string;

  const calculateMinWidth = () => {
    if (!justCopied && !justErrored) {
      minWidth = '0px';
      setTimeout(() => {minWidth = `${buttonEl.offsetWidth}px`})
      
    }
  }

  onMount(() => {
    calculateMinWidth();
  })

  let copiedTextTimeout = undefined;
  const copyText = async() => {
    try {
      await navigator.clipboard.writeText(toCopy);
      if (copiedTextTimeout) {
        clearTimeout(copiedTextTimeout);
      }
      justCopied = true;
    } catch (error) {
      console.error(error.message);
      justErrored = true;
    } finally {
      copiedTextTimeout = setTimeout(() => { 
        justCopied = false;
        justErrored = false;
      }, 1000)
    }
  }

  $: {
    if (justErrored) {
      buttonText = errorText;
    } else if (justCopied) {
      buttonText = successText;
    } else {
      buttonText = text;
    }
  }
</script>

<svelte:window on:resize={debounce(calculateMinWidth, 100)} />

<ActionButton
  buttonClasses="border {justCopied || justErrored ? 'changed' : ''} {buttonClasses}"
  buttonStyles={`min-width: ${minWidth}`}
  design={design}
  on:click={copyText}
  bind:buttonEl
>
  <span>{buttonText}</span>
  {#if justCopied}
    <Icon icon="Check" size="medium"></Icon>
  {:else}
    <Icon icon="Copy" size="medium"></Icon>
  {/if}
</ActionButton>
