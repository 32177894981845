import { formatDateStringAsTimeAndDate, formatDecimalAsPercentage } from '$shared/lib/formatters';

export const sharedTableSortingBanlist: string[] = ['actions', 'select', 'actions2', 'expanded', 'roles', 'selected'];

interface FormatCellOptions {
	header: string;
	value: string;
}

interface SortKey {
	id: string;
	order: string;
}

interface TableDataQueryOptions {
	filterText: string | null;
	sortKey: SortKey | null;
	startDate: string | null;
	endDate: string | null;
}

interface LoadTableDataOptions extends TableDataQueryOptions {
	dataUrl: string;
}

interface CreateAndDownloadCSVOptions {
	csvData: any;
	tableName: string | null;
	filterText: string | null;
	startDate: string | null;
	endDate: string | null;
}


export function formatCellValue(options: FormatCellOptions) {

	if (headersInDateStringFormat.includes(options.header)) {
		console.log("Formatted", options.header, " ", options.value, " into ", formatDateStringAsTimeAndDate(options.value))
		return formatDateStringAsTimeAndDate(options.value);
	}
	if (headersInDecimalFormat.includes(options.header)) {
		return formatDecimalAsPercentage(options.value);
	}
	if (options.header === 'roles') {
		return getFormattedPermissions(options.value);
	}
	return options.value;
}

// formatted lists
const headersInDateStringFormat = [
	'createdAt',
	'updatedAt',
	'lastActive',
	'video_last_activity',
	'last_activity',
	'user_created_at',
	'user_last_activity',
	'user_expires_at'
];
const headersInDecimalFormat = ['completionRate', 'video_lesson_completion_rate', 'completion_rate'];

function getFormattedPermissions(value: string[]) {
  if (!value || value.length === 0) {
    return 'Learner';
  }

	if (value.includes('account_owner')) {
		return 'Administer Account & Users';
	}

  // if the value is a string, convert it to an array by splitting on commas
  if (typeof value === 'string') {
    value = value.split(',');
  }

  let roles = value.map((role) => {
    return getRoleName(role);
  });
  return roles.join(', ');
}

function getRoleName(value: string) {
	if (value.includes('manage_comments')) {
		return 'Manage Comments';
	}
	if (value.includes('manage_curations')) {
		return 'Manage Curations';
	}
	if (value.includes('manage_users')) {
		return 'Manage Users';
	}
	if (value.includes('view_analytics')) {
		return 'View Analytics';
	}
}

function constructTableDataQuery(options: TableDataQueryOptions) {
	let queryParams = new URLSearchParams();

  if (options.filterText) {
    queryParams.set('filter', options.filterText);
  }
  if (options.sortKey) {
  	queryParams.set('sort', options.sortKey.id);
  	queryParams.set('order', options.sortKey.order);
  }
  if (options.startDate && options.endDate) {
  	queryParams.set('start_date', options.startDate);
  	queryParams.set('end_date', options.endDate);
  }

  return `?${queryParams.toString()}`;
}

export async function loadTableDataAsCSV(options: LoadTableDataOptions) {
	let query = constructTableDataQuery({
		filterText: options.filterText || null,
		sortKey: (options.sortKey?.id && options.sortKey?.order) ? options.sortKey : null,
		startDate: options.startDate || null,
		endDate: options.endDate || null
	});
  try {
    const url = `${options.dataUrl}.csv${query}`;
    const response = await fetch(url);
    return await response.text();
  } catch(e) {
    console.error(e.message);
  }
}

export async function loadTableDataAsJSON(options: LoadTableDataOptions) {
	let query = constructTableDataQuery({
		filterText: options.filterText || null,
		sortKey: (options.sortKey?.id && options.sortKey?.order) ? options.sortKey : null,
		startDate: options.startDate || null,
		endDate: options.endDate || null
	});
  try {
    const url = `${options.dataUrl}.json${query}`;
    const response = await fetch(url);
    return await response.json();
  } catch(e) {
    console.error(e.message);
  }
}

export function createAndDownloadCSV(options: CreateAndDownloadCSVOptions
) {
	const blob = new Blob([options.csvData], { type: 'text/csv' });
  const currentDate = new Date();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  let filename = `${options.tableName} ${currentDate.toISOString()}`;
  if (options.startDate && options.endDate) {
    filename += ` - ${options.startDate} to ${options.endDate}`;
  }
  if (options.filterText) {
    filename += ` - filtered to ${options.filterText}`;
  }

  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
}
