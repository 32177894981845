<script>
  import Loading from '$comp/ui/loading/Loading.svelte';

  export let loading = 0;
  export let title = '';
</script>

<div class="flex mb-4 space-x-4">
  <div class="text-xl">{title}</div>
  <Loading {loading} />
</div>
