<svelte:options customElement={{ tag: "bt-user-management-wrapper", shadow: "none" }} />

<script lang="ts">
  // Svelte imports
  import { onMount } from 'svelte';
	import { writable } from 'svelte/store';
  // External imports
  import { 
    createSvelteTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    renderComponent
  } from '@tanstack/svelte-table';
  import type { 
    ColumnDef, 
    TableOptions,
    OnChangeFn,
    SortingState,
    RowSelectionState,
    PaginationState
  } from '@tanstack/svelte-table';
  // Other components
	import UsersDataTableActions from '$comp/user-management/UsersDataTableActions.svelte';
	import DataTableCheckbox from '$shared/data-table/data-table-checkbox.svelte';
	import AddUser from '$comp/user-management/AddUser.svelte'
	import BulkRemove from '$comp/user-management/BulkRemove.svelte';
	import BulkUpload from '$comp/user-management/BulkUpload.svelte';
	import ResendEmail from '$comp/user-management/ResendEmail.svelte';
	import { Button } from '$lib/components/ui/button';
  // Table config / helpers / stores
  import { userTableTooltips } from '$comp/user-management/userManagementConfig';
  import { checkedRows } from './stores';
	import {
		loadTableDataAsJSON,
		loadTableDataAsCSV,
		createAndDownloadCSV,
		formatCellValue
	} from '$shared/data-table/tableHelpers';
  // The actual table component
  import DataTable from '$lib/components/shared/data-table/DataTable.svelte';

	export let showCompany: boolean = false;
	export let turnOffComments: boolean = false;

  type User = {
    email: string
    expires_at: string
    full_name: string
    id: number
    roles: any[]
    status: string
    updated_at: string
  }

  let csvIsProcessing: Boolean = false;

  const defaultColumns: ColumnDef<User>[] = [
  	{
  		accessorKey: 'id',
  		header: "ID",
  		enableGlobalFilter: false
  	},
  	{
  		id: 'select-col',
    	cell: (info) => renderComponent(DataTableCheckbox, {
        isSelected: info.row.getIsSelected() || $checkedRows[info.row.getValue('id')], 
        rowId: info.row.getValue('id'), 
        rowStore: checkedRows
      })
  	},
    {
      accessorKey: 'email',
      header: 'Email'
    },
    {
      accessorKey: 'full_name',
      cell: info => formatCellValue({header: 'full_name', value: info.getValue()}),
      header: () => 'Name',
    },
    {
      accessorKey: 'updated_at',
      header: () => 'Last Activity',
      enableGlobalFilter: false
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableGlobalFilter: false
    },
    {
      accessorKey: 'expires_at',
      header: 'Expires at',
      enableGlobalFilter: false
    },
    {
      accessorKey: 'roles',
      header: 'Permissions',
      cell: (info) => formatCellValue({header: 'roles', value: info.getValue()}),
      enableSorting: false,
      enableGlobalFilter: false
    },
    {
    	accessorKey: 'actions',
    	header: '',
    	enableSorting: false,
    	enableGlobalFilter: false,
    	cell: (info) => renderComponent(UsersDataTableActions, {row: info.row.original, cback: handleRemoveUser})
    }
  ]

  if (showCompany) {
    defaultColumns.splice(4, 0, {
      accessorKey: 'company',
      header: 'Company',
      enableGlobalFilter: false
    })
  }

  // Tanstack Table plugin states
  let sorting: SortingState = [];
  let rowSelection: RowSelectionState = [];
  let pagination: PaginationState = { pageIndex: 0, pageSize: 10 };

  const defaultData = [{},{},{},{},{}];

  const setSorting: OnChangeFn<SortingState> = updater => {
    if (updater instanceof Function) {
      sorting = updater(sorting)
    } else {
      sorting = updater
    }
    options.update(old => ({
      ...old,
      state: {
        ...old.state,
        sorting,
      },
    }))
  }
  const setRowSelection: OnChangeFn<RowSelectionState> = updater => {
    if (updater instanceof Function) {
      rowSelection = updater(rowSelection)
    } else {
      rowSelection = updater
    }
    $checkedRows = rowSelection;
    options.update(old => ({
      ...old,
      state: {
        ...old.state,
        rowSelection,
      },
    }))
  }
  const setPagination: OnChangeFn<PaginationState> = updater => {
    if (updater instanceof Function) {
      pagination = updater(pagination)
    } else {
      pagination = updater
    }
    options.update(old => ({
      ...old,
      state: {
        ...old.state,
        pagination,
      },
    }))
  }

  const options = writable<TableOptions<User>>({
    data: defaultData,
    columns: defaultColumns,
    state: {
    	columnVisibility: {
    		'id': false,
    	},
    	sorting,
    	rowSelection,
    	pagination
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const fetchAndRender = async() => {
		await loadTableDataAsJSON({
			dataUrl: 'users'
		}).then(userData => {
  		options.update(options => ({
  			...options,
  			data: userData
  		}));
  		$table.setRowSelection([]);
  		$checkedRows = {};
  	});
  }

  const table = createSvelteTable(options)

  onMount(async () => {
  	fetchAndRender();
  });

  const handleEditUser = () => {
  	let lastPageIndex = $table.getState().pagination.pageIndex;
  	fetchAndRender().then(() => {
  		$table.setPagination({ pageIndex: lastPageIndex, pageSize: 10 })
  	});
  	$checkedRows = [];
  }
  const handleRemoveUser = () => {
  	let lastPageIndex = $table.getState().pagination.pageIndex;
  	fetchAndRender().then(() => {
  		$table.setPagination({ pageIndex: lastPageIndex, pageSize: 10 })
  	});
  }

  const handleExportCsv = () => {
    if (csvIsProcessing) {
    	return;
    } else {
    	csvIsProcessing = true;
    }

    /* See below block comment
    let filterText = '';
    let sortKey = {};
    let sortingPayload = null;

    if ($table.getState().sorting.length > 0) {
    	sortingPayload = {
    		id: $table.getState().sorting[0].id,
    		order: $table.getState().sorting[0].desc ? 'desc' : 'asc'
    	}
    }
    */

    // Temp removing the filter text since it's currently non-functional
    // but the filterText val to pass is $table.getState().globalFilter
    // and the sortKey val would be sortingPayload
    loadTableDataAsCSV({
 			dataUrl: '/users',
    }).then(csvData => {
    	createAndDownloadCSV({
    		csvData: csvData,
    		tableName: 'User Management',
    	})
    }).finally(() => {
    	csvIsProcessing = false;
    })
  }
</script>

<div class="p-2">
	<DataTable 
		table={table}
		tableName='User Management'
		tooltips={userTableTooltips}
		canFilter
		filterLabel="Filter by email or name"
		selectedRowStore={$checkedRows}
	>
		<svelte:fragment slot="buttons">
			<Button variant="outline" size="default" on:click={handleExportCsv} class="mt-2 ml-2">Download</Button>
			<ResendEmail />
			<BulkRemove on:remove-user={handleRemoveUser} />
			<BulkUpload />
			<AddUser on:edit-user={handleEditUser} {showCompany} {turnOffComments} />
		</svelte:fragment>
	</DataTable>
	{#if Object.keys($checkedRows).length > 0}
  <Button
  	variant="outline"
  	class="mt-4"
  	on:click={() => {
	    $table.resetRowSelection();
	    $checkedRows = {} 
	  }}
	>
			Unselect all
	</Button>
	{/if}
</div>
