import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "status", "textarea", "radio"];
  static values = {
    focus: Boolean,
    answer: String
  };

  connect() {
    this.timeout = null;
    this.duration = this.data.get("duration") || 1000;
    this.maxTries = 100;

    if (this.focusValue) this.focus();
  }

  // The textareaTarget for the answer form is an `sl-textarea` element -- that
  // is, a fancy textarea control from the Shoelace module. It takes a moment to
  // initialize, which can mean that it's not always ready to accept the `focus`
  // method. Therefore, we capture the error when this happens and retry the
  // call to `focus` in 10 ms. In order to ensure we don't retry infinitely, we
  // limit the number of retries via `maxTries`.
  focus() {
    clearTimeout(this.timeout);

    this.maxTries--;
    if (this.maxTries <= 0) return;

    try {
      this.textareaTarget.focus();
    } catch(e) {
      this.timeout = setTimeout(() => {
        this.focus();
      }, 10);
    }
  }

  save() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this.status) {
        this.statusTarget.textContent = "Saving...";
      }
      Rails.fire(this.formTarget, "submit");
    }, this.duration);
  }

  radio_save(event) {
    // the timeout in the regular save messes up the retrieval of the checked property
    // and the unusual method below is the only method I found that reported the checked status accurately
    if (event.target.attributes.getNamedItem("checked") !== null) {
      fetch(`/answers/${this.answerValue}`, {
        method: "DELETE",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        }
      });
      event.target.removeAttribute("checked");
      event.target.checked = false;
    } else {
      Rails.fire(this.formTarget, "submit");
    }
  }

  setStatus(message) {
    this.statusTarget.textContent = message;

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "";
    }, 2000);
  }
}
